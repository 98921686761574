<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">List of distribution</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information of distribution
                </h6>

                <div class="row">
                  <div class="col-lg-2">
                    <base-select
                      alternative=""
                      required
                      label="Type"                      
                      :options="Service.types()"
                      input-classes="form-control-alternative"
                      :errors="errors.type"
                      v-model="model.type"                      
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-select
                      alternative=""
                      required
                      :label="
                        model.carga.id
                          ? 'Cargo | updated on ' +
                            this.$options.filters.date(
                              model.carga.atualizado_data
                            )
                          : 'Cargo'
                      "
                      placeholder="Select the cargo"
                      :options="cargas"
                      input-classes="form-control-alternative"
                      :errors="errors.carga_tipo_id"
                      v-model="model.carga_tipo_id"
                      @change="setCarga()"
                    />
                  </div>
                  <div class="col-lg-3">
                    <base-input
                      alternative=""
                      required
                      label="Start of period"
                      type="date"
                      input-classes="form-control-alternative"
                      :errors="errors.periodo_inicio"
                      v-model="model.periodo_inicio"
                    />
                  </div>
                  <div class="col-lg-3">
                    <base-input
                      alternative=""
                      required
                      type="date"
                      label="End of period"
                      input-classes="form-control-alternative"
                      :errors="errors.periodo_fim"
                      v-model="model.periodo_fim"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      required
                      label="Subject"
                      placeholder="subject"
                      input-classes="form-control-alternative"
                      :errors="errors.assunto"
                      v-model="model.assunto"
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        Send date<span>*</span></label
                      >
                      <datetime
                        value-zone="America/Sao_Paulo"
                        zone="America/Sao_Paulo"
                        type="datetime"
                        input-class="form-control form-control-alternative"
                        v-model="model.data_envio"
                      ></datetime>
                      <div
                        class="text-danger invalid-feedback"
                        style="display: block"
                        v-for="erro in errors['data_envio']"
                        v-if="errors['data_envio']"
                      >
                        {{ erro }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        Email body<span>*</span></label
                      >
                      <ckeditor
                        :editor="editor"
                        v-model="model.body"
                        :config="editorConfig"
                      ></ckeditor>
                      <div
                        class="text-danger invalid-feedback"
                        style="display: block"
                        v-for="erro in errors['body']"
                        v-if="errors['body']"
                      >
                        {{ erro }}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>

            <button
              type="submit"
              class="btn base-button btn-dark float-left"
              @click="$router.push('/lista_distribuicao/listar')"
            >
              See listing
            </button>
            <button
              type="submit"
              class="btn base-button btn-primary float-right"
              :disabled="loading"
              @click="submit()"
            >
              Save
            </button>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-datetime/dist/vue-datetime.css";
import Service from "@/services/ListaDistribuicao";
import { Datetime } from "vue-datetime";
import CargasTiposService from "@/services/CargasTipos";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import "@ckeditor/ckeditor5-build-classic/build/translations/pt";
export default {
  name: "usuario_form",
  components: {
    datetime: Datetime,
  },
  created() {
    if (this.$route.params.id) {
      this.model.id = this.$route.params.id;
      this.find();
    }
    CargasTiposService.get({ with: ["atualizador"] }).then((response) => {
      this.cargas = response.data;
    });
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // language: "pt",
        heigth: 600,
      },
      errors: {},
      agencias: [],
      cargas: [],
      loading: false,
      portos: [],
      Service,

      model: {
        type:1,
        nome: "",
        email: "",
        data_envio: null,
        carga_tipo_id: null,
        periodo_inicio: null,
        periodo_fim: null,
        carga: {},
        id: null,
      },
    };
  },
  methods: {
    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id, { with: "carga" })
        .then((response) => {
          this.model = response.data;
          this.model.cargas = [];
          this.fixCargas();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    setCarga() {
      let carga = this.cargas.find(
        (carg) => carg.id == this.model.carga_tipo_id
      );
      if (carga) {
        this.model.carga = carga;
        this.model.body = carga.template_body;
      } else {
        this.model.carga_tipo_id = null;
        this.model.carga = {};
        this.model.body = null;
      }
    },

    submit() {
      this.loading = true;
      this.errors = {};
      Service.submit(this.model)
        .then((response) => {
          this.model = response.data;
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Lista de distribuição salva com sucesso!",
            horizontalAlign: "center",
          });
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
    },
  },
};
</script>
<style>
.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
