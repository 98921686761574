<template>
  <div>
    <base-header  class="pb-6 pb-6 pt-5 pt-md-5">
  </base-header>
  <div class="container-fluid mt-5">

    <card body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="card-header border-0">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0">List <i class="fa fa-spinner fa-spin" v-if="loading"></i></h3>
          </div>
          <div class="col text-right">
             <base-button type="info" icon size="sm" @click="cleanFiltros()">
            <i class="fas fa-filter mr-2"></i>
            <span class="btn-inner--text">Clear filters</span>
          </base-button>
            <base-button type="primary" icon size="sm" @click="$router.push('/lista_distribuicao/adicionar')">
            <span class="btn-inner--text">New</span>
          </base-button>
        </div>
      </div>
      <!-- pesquisa -->
      <div class="row align-items-center mt-3">
       <div class="col-lg-6">
        <base-input
        alternative=""
        label="Subject"
        placeholder="search by subject"
        input-classes="form-control-alternative"
        v-model.lazy="search.assunto"
        />
      </div>

      <div class="col-lg-5">
       <multiple-select
       multiple
       label="Cargos"
       placeholder="select the cargos"
       :options="cargas"
       v-model="search.carga_tipo_id"
       />
     </div>
     <div class="col-lg-1">
      <div class="form-group has-label">
        <label class="form-control-label" style="color: transparent;"> search</label>
        <button type="submit" class="btn base-button btn-primary w-100"
        :disabled="loading"
        @click="listar()"><i class="fa fa-search"></i></button>
      </div>
      <!-- <button type="submit" class="btn base-button btn-primary w-100" @click="submit()"><i class="fa fa-check"></i></button> -->
    </div>

  </div>
  <!-- pesquisa -->
</div>

<div class="table-responsive"  style="overflow: visible;">
  <table
  class="table align-items-center table-flush tablesorter"
  tbody-classes="list"
  :data="list"
  >
  <thead class="thead-light">
    <tr>
      <th>Subject</th>
      <th>Send date</th>
      <th>Cargos</th>
      <th>Status</th>
      <th>File</th>
      <th></th>
    </tr>
  </thead>

  <tbody class="list">
    <tr v-for="(row, index) in list" :key="list.id">
      <td>
        {{ row.assunto }}
      </td>
      <td>
        {{ row.data_envio |date }}
      </td>

      <td>

          {{ row.carga.name }}

      </td>

      <td>
        <span v-if="row.status">SENT</span>
        <span v-else>WAITING TO BE SENT</span>
      </td>

        <td>

          <a :href="row.arquivo_url" download class="btn btn-primary"> <i class="fa fa-download"></i>  </a>

      </td>
      <!--     <td>
            <a style="cursor: pointer" @click="onProFeature" class="mr-2">
              <i class="fas fa-user-edit"></i>
            </a>
            <a style="cursor: pointer" @click="$root.openExcluirModal(
              row,
              'Você gostaria de excluir o usuário '+ row.name +'?',
              Service)">
              <i class="fas fa-trash"></i>
            </a>
          </td> -->
          <td class="text-right">
            <base-dropdown class="dropdown" position="right">
            <a
            slot="title"
            class="btn btn-sm btn-icon-only text-light"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            >
            <i class="fas fa-ellipsis-v"></i>
          </a>

          <template>
           <router-link  class="dropdown-item" :to="'/lista_distribuicao/editar/'+row.id"><i class="fas fa-user-edit"></i> edit</a></router-link>
           <a class="dropdown-item" href="#" @click="$root.openExcluirModal(
            row,
            'Would you like to delete a weekly news '+ row.assunto +'?',
            Service)"><i class="fas fa-trash" style="min-width: 20px"></i> delete</a>
          </template>
        </base-dropdown>
      </td>
    </tr>
    <tr v-if="!list.length && !loading">
      <td colspan="100" style="text-align: center;">
        No list found
      </td>
    </tr>
    <tr v-if="loading">
      <td colspan="100" style="text-align: center;">
        <i class="fa fa-spinner fa-spin" v-if="loading" style="font-size: 20px"></i>
      </td>
    </tr>
  </tbody>

</table>
</div>

<div class="card-footer d-flex justify-content-end">
  <base-pagination :pagination="pagination" @change="listar()" align="center"></base-pagination>
</div>
</card>
</div>
<excluir-modal :show="$root.modal.excluir.isOpen"
:mensagem="$root.modal.excluir.mensagem"
:model="$root.modal.excluir.model"
:service="$root.modal.excluir.service"
@close="handleExcluir"
></excluir-modal>
</div>
</template>
<script>
  import Service from "@/services/ListaDistribuicao";
  import CargasTiposService  from "@/services/CargasTipos";
  export default {
    data() {
      return {
        Service,
        cargas:[],
        loading:false,
        pagination: {
          page: 1,
          last_page: 1,
          per_page: 20,
          total:1
        },
          search:{
          assunto:'',
          // email:'',
          carga_tipo_id:[]
        },
         defaultSearch:{},
        orderBy: {
          campo: "assunto",
          ordem: "asc",
        },
        list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
        ],
      };
    },
    created(){
      this.defaultSearch = {...this.search};
      this.listar();
      CargasTiposService.get().then(response => {
        this.cargas = response.data
      });
    },
    watch: {
      // search: {
      //   deep: true,
      //   handler() {
      //     this.pagination.page = 1;
      //     this.listar();
      //   },
      // },
      orderBy: {
        deep: true,
        handler() {
          this.pagination.page = 1;
          this.listar();
        },
      },
    },
    methods: {
     handleExcluir(updated){
      this.$root.modal.excluir.isOpen = false
      if(updated)
        this.listar();
    },
     cleanFiltros(){
      this.search = {...this.defaultSearch};
      this.listar();
    },
    listar(){
      this.loading = true;
      this.list = [];
      Service.get({
        with:['carga'],
        ...this.pagination,
        ...this.search
      }).then(response => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
<style>
</style>
