<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">User</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>

            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information of user
                </h6>

                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                    alternative=""
                    required
                    label="Name"
                    placeholder="user name"
                    input-classes="form-control-alternative"
                    :errors="errors.name"
                    v-model="model.name"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                    alternative=""
                    required
                    type="email"
                    label="Email"
                    placeholder="name@williams.com"
                    input-classes="form-control-alternative"
                    :errors="errors.email"
                    v-model="model.email"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-select
                    alternative=""
                    label="Profile"
                    :errors="errors.role_id"
                    placeholder="Select the profile"
                    :options="Service.roles()"
                    @change="verificaRole()"
                    required
                    input-classes="form-control-alternative"
                    v-model="model.role_id"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                    alternative=""
                    :required="model.id ? false : true"
                    type="password"
                    label="Password"
                    placeholder="********"
                    :errors="errors.password"
                    input-classes="form-control-alternative"
                    v-model="model.password"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                    alternative=""
                    :required="model.id ? false : true"
                    type="password"
                    label="Password Confirmation"
                    placeholder="********"
                    :errors="errors.password_confirmation"
                    input-classes="form-control-alternative"
                    v-model="model.password_confirmation"
                    />
                  </div>
                  <div class="col-lg-4">
                   <multiple-select
                   multiple
                   :disabled="model.role_id ==1"
                   :close-on-select="false"
                   :clear-on-select="false"
                   label='Port'
                   placeholder="Select the port""
                   :options="portos"
                   optionsValue="name"
                   v-model="model.porto_ids"
                   :errors="errors.porto_ids"
                   />
                 </div>
               </div>


                <!--     <hr class="my-4" />

            <h6 class="heading-small text-muted mb-4">
              Informações do operador
            </h6>
            <div class="pl-lg-4">
              <div class="row">
                <div class="col-md-12">
                  <base-input
                  alternative=""
                  label="Address"
                  placeholder="Home Address"
                  input-classes="form-control-alternative"
                  v-model="model.address"
                  />
                </div>
              </div>

            </div> -->
          </form>
        </template>

        <button
        type="submit"
        class="btn base-button btn-dark float-left"
        @click="$router.push('/usuarios/listar')"
        >
        See listing
      </button>
      <button
      type="submit"
      class="btn base-button btn-primary float-right"
      :disabled="loading"
      @click="submit()"
      >
      Save user
    </button>
  </card>
</div>
</div>
</div>
</div>
</template>
<script>

  import Service from "@/services/Usuarios";
  import PortosService from "@/services/Portos";
  export default {
    name: "usuario_form",
    created(){
     PortosService.get({        
     }).then(response => {
      this.portos = response.data

    });
     if(this.$route.params.id){
      this.model.id =this.$route.params.id;
      this.find();
    }

  }, 
  data() {
    return {
      errors:{},
      loading:false,
      portos:[],
      Service,

      model: {
        nome: "",
        email: "",
        password:'',
        password_confirmation:'',
        role_id: null, 
        porto_ids:[],     
        id:null    
      },
    };
  },
  methods: {
   find(){
    this.loading = true;
    this.errors = {};
    Service.find(this.model.id,{with:'portos'}).then(response => {
      this.model = response.data;
      this.model.porto_ids = response.data.portos.map(porto => porto.id);
      this.loading = false;        
    }).catch((err) => {
      this.loading = false;
    });
  },
  verificaRole(){
    if(this.model.role_id == 1){
      this.model.porto_ids = [];        
    }
  },
  submit() {
    this.loading = true;
    this.errors = {};
    Service.submit(this.model)
    .then((response) => {
      this.model = response.data;
      this.loading = false;
      this.$notify({
        type: "success",
        message: "User created successfully!",
        horizontalAlign: "center",
      });
    })
    .catch((err) => {
      this.loading = false;
      if (err.response && err.response.data && err.response.data.errors) {
        let errors = err.response.data.errors;
        this.errors = errors;
        this.$notify({
          type: "danger",
          message: "Please check the form",
          horizontalAlign: "center",
        });
      }
    });
  },
},
};
</script>
<style></style>
