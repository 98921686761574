<template>
  <ul class="pagination" :class="[size && `pagination-${size}`, align && `justify-content-${align}`]">
    <li class="page-item prev-page" :class="{disabled: pagination.page === 1}">
      <a class="page-link" aria-label="Previous" @click="prevPage">
        <span aria-hidden="true"><i class="fa fa-angle-left" aria-hidden="true"></i></span>
      </a>
    </li>
    <li class="page-item" :class="{active: pagination.page === item}"
    :key="item"
    v-for="item in range(minPage, maxPage)">
    <a class="page-link" @click="changePage(item)">{{item}}</a>
  </li>
  <li class="page-item next-page" :class="{disabled: pagination.page === totalPages}">
    <a class="page-link" aria-label="Next" @click="nextPage">
      <span aria-hidden="true"><i class="fa fa-angle-right" aria-hidden="true"></i></span>
    </a>
  </li>
</ul>
</template>
<script>
  export default {
    name: "base-pagination",
    props: {
      size: {
        type: String,
        default: "",
        description: "Pagination size"
      },
      align: {
        type: String,
        default: "",
        description: "Pagination alignment (e.g center|start|end)"
      },
      pagination:{
       type: Object,
       default: ()=>{},
     }
   },
   computed: {
    totalPages() {
      if (this.pagination.last_page > 0) return this.pagination.last_page;
      if (this.pagination.total > 0) {
        return Math.ceil(this.pagination.total / this.pagination.per_page);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.pagination.page >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.pagination.page;
        if (newMaxPage > this.pagination.totalPages) {
          return this.pagination.totalPages - this.pagesToDisplay + 1;
        }
        return this.pagination.page - pagesToAdd;
      } else {
        return 1;
      }
    },
    maxPage() {
      if (this.pagination.page >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.pagination.page;
        console.log(pagesToAdd,newMaxPage);
        if (newMaxPage < this.pagination.totalPages) {
          return newMaxPage;
        } else {
          return this.totalPages;
        }
      } else {
        return this.pagesToDisplay;
      }
    }
  },
  data() {
    return {
      defaultPagesToDisplay: 5
    };
  },
  methods: {
    range(min, max) {
      let arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      this.pagination.page = item;
      this.$emit("change");
    },
    nextPage() {
      if (this.pagination.page < this.totalPages) {
        this.pagination.page = this.pagination.page + 1
        this.$emit("change");
      }
    },
    prevPage() {
      if (this.pagination.page > 1) {
        this.pagination.page = this.pagination.page - 1;
        this.$emit("change");
      }
    }
  },
  watch: {
    // pagination.per_page() {
    //   this.$emit("input", 1);
    // },
    // pagination.total() {
    //   this.$emit("input", 1);
    // }
  }
};
</script>
