<template>
  <div v-if="type">
    <div class="form-row">
      <!-- import -->
      <div class="col-lg-12">
        <card shadow type="secondary">
          <template>
            <div class="row">
              <div class="col-lg-8">
                <div class="form-group has-label">
                  <label class="form-control-label"> Balance QTY </label>

                  <money
                    disabled
                    class="form-control form-control-alternative"
                    :value="saldo"
                    v-bind="qtd"
                  ></money>
                  <div
                    class="text-danger invalid-feedback"
                    style="display: block"
                    v-for="erro in errors.qty_import"
                    v-if="errors.qty_import"
                  >
                    {{ erro }}
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <label class="form-control-label w-100 transparent"
                  >button</label
                >
                <base-button
                  type="primary  w-100 input-padding"
                  style=""
                  class="float-right"
                  icon
                  size="sm"
                  @click="addTrabalhador()"
                >
                  <i class="fas fa-plus mr-2"></i>
                  <span class="btn-inner--text">Add {{ nomeSingular }}</span>
                </base-button>
              </div>
              <div class="col-lg-12">
                <h6 class="heading-small text-muted mb-2">
                  {{ nomePlural }}
                </h6>
              </div>
            </div>
            <div class="row">
              <div
                class="col-lg-6"
                v-for="(trabalhador, index) in model[variavel]"
              >
                <div class="form-row">
                  <div class="col-lg-6">
                    <multiple-select
                      alternative=""
                      :label="nomeSingular"
                      :errors="
                        errors[
                          errorSufix +
                            variavel +
                            '.' +
                            index +
                            '.trabalhador_id'
                        ]
                      "
                      :placeholder="'Select the ' + nomeSingular"
                      :options="trabalhadores"
                      @btn="openModalCreate('trabalhador', variavel, index)"
                      :edit="true"
                      @edit="
                        openModalCreate(
                          'trabalhador',
                          variavel,
                          index,
                          trabalhador.trabalhador_id
                        )
                      "
                      addonLeftIcon=" fa fa-plus"
                      required
                      input-classes="form-control"
                      v-model="trabalhador.trabalhador_id"
                    />
                  </div>

                  <div class="col-lg-4">
                    <div class="form-group has-label">
                      <label class="form-control-label"> QTY </label>

                      <money
                        class="form-control form-control-alternative"
                        v-model="trabalhador.qty"
                        v-bind="qtd"
                      ></money>
                      <div
                        class="text-danger invalid-feedback"
                        style="display: block"
                        v-for="erro in errors[
                          errorSufix + variavel + '.' + index + '.qty'
                        ]"
                        v-if="errors[variavel + '.' + index + '.qty']"
                      >
                        {{ erro }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group has-label">
                      <label
                        class="form-control-label"
                        style="color: transparent"
                      >
                        button
                      </label>
                      <button
                        class="btn base-button btn-danger w-100"
                        :disabled="loading"
                        @click="() => model[variavel].splice(index, 1)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </card>
      </div>
      <!-- import -->
    </div>
    <modal :show="modal.create.show" @close="handleCloseModal">
      <template v-slot:header> Add new </template>

      <template>
        <generic-form
          v-if="modal.create.show"
          :service="modal.create.service"
          :type="modal.create.type"
          :id="modal.create.id"
          :params="modal.create.params"
          @success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>
<script>
import Service from "@/services/Lineups";
import TrabalhadoresService from "@/services/Trabalhadores";
import GenericForm from "./../../Generic/Form.vue";
import { Money } from "v-money";
export default {
  name: "lineups_cargas",
  components: {
    Money,
    "generic-form": GenericForm,
  },

  props: {
    isModal: {
      default: 0,
    },
    model: {
      // default: ()=>{},
    },

    cargasTipos: {
      default: () => [],
    },
    trabalhadores: {
      default: () => [],
    },
    errorSufix: {
      default: "",
    },
    type: {
      default: 1,
    },
    errors: {
      default: () => [],
    },
  },
  data() {
    return {
      qtd: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " (t)",
        precision: 2,
        masked: false,
      },
      nomePlural: "",
      nomeSingular: "",
      variavel: "",

      loading: false,
      Service,
      modal: {
        create: {
          show: false,
          service: {},
          type: null,
          params: {},
          model: null,
          index: null,
        },
      },
    };
  },
  created() {
    this.checkType();
  },
  computed: {
    saldo() {
      let total = 0;

      if (this.model[this.variavel]) {
        total += this.model[this.variavel].reduce(
          (acumulador, elemento) => (acumulador += parseFloat(elemento.qty)),
          0
        );
      }
      // this.model.qty_import = total;
      return total;
    },
  },
  watch: {
    type() {
      this.checkType();
    },
    errors: {
      deep: true,
      handler() {
        // console.log(this.errorSufix + this.variavel)
      },
    },
  },
  methods: {
    checkType() {
      if (this.type == 1) {
        this.setImport();
        this.model.embarcadores = [];
      }

      if (this.type == 2) {
        this.setExport();
        this.model.recebedores = [];
      }

      this.$forceUpdate();
    },
    addTrabalhador() {
      //recebedor
      return this.model[this.variavel].push({
        qty: 0,
        trabalhador_id: null,
        type: this.type,
      });
    },
    openModalCreate(type, model, index, id) {
      this.modal.create.id = id;
      switch (type) {
        case "agencia":
          this.modal.create.service = AgenciasService;
          this.modal.create.params = {};
          break;

        case "navio":
          this.modal.create.service = NaviosService;
          this.modal.create.params = {};
          break;

        case "trabalhador":
          this.modal.create.service = TrabalhadoresService;
          this.modal.create.params = {};
          this.modal.create.model = model;
          this.modal.create.index = index;
          break;

        case "afretador":
          this.modal.create.service = AfretadoresService;
          this.modal.create.params = {};

          break;
        case "lineup_porto":
          this.modal.create.service = LineupsPortosService;
          this.modal.create.params = {};
          this.modal.create.model = model;

          break;

        case "carga":
          if (!this.model.carga_tipo_id) {
            this.$notify({
              type: "danger",
              message: "Please select the cargo type",
              horizontalAlign: "center",
            });
            return false;
          }
          this.modal.create.service = CargasService;
          this.modal.create.params = { tipo_id: this.model.carga_tipo_id };
          break;
      }

      this.modal.create.type = type;
      this.modal.create.show = true;
    },
    handleModalForm(model) {
      let arrayOfFields = null;
      switch (this.modal.create.type) {
        case "trabalhador":
          this.model[this.modal.create.model][this.modal.create.index][
            "trabalhador_id"
          ] = model.id;
          arrayOfFields = "trabalhadores";
          // this.trabalhadores.push(model);
          break;
      }

      if (this.modal.create.id) {
        let field = this[arrayOfFields].find((field) => field.id == model.id);
        this.$helper.set(field, model, Object.keys(field));
      } else {
        this[arrayOfFields].unshift(model);
      }

      this.modal.create.type = null;
      this.modal.create.show = false;
      if (!this.isModal)
        document.querySelector("body").classList.remove("modal-open");
      // this.$forceUpdate();
    },
    handleCloseModal() {
      this.modal.create.show = false;
      if (!this.isModal)
        document.querySelector("body").classList.remove("modal-open");
    },
    setImport() {
      this.nomePlural = "Receivers";
      this.variavel = "recebedores";
      this.nomeSingular = "receiver";
      this.$forceUpdate();
    },
    setExport() {
      this.nomePlural = "Shippers";
      this.variavel = "embarcadores";
      this.nomeSingular = "shipper";
      this.$forceUpdate();
    },
  },
};
</script>
<style>
button.input-padding {
  padding: 0.625rem 0.75rem;
}
.transparent {
  color: transparent;
}
</style>
