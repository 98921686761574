import Vue from "vue";
import Router from "vue-router";
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";
// Example pages
import UserProfile from "@/views/Usuarios/UserProfile.vue";
import ListUserPage from "@/views/Examples/UserManagement/ListUserPage.vue";

import ListarUsuarios from "@/views/Usuarios/Listar.vue";
import AtividadesUsuarios from "@/views/Usuarios/Logs/List.vue";
import MostrarAtividadeUsuario from "@/views/Usuarios/Logs/Show.vue";
import FormUsuarios from "@/views/Usuarios/Form.vue";

import ListarContatos from "@/views/Contatos/Listar.vue";
import FormContatos from "@/views/Contatos/Form.vue";

import ImportarContatos from "@/views/Contatos/Importar.vue";

import ListarWeeklyNews from "@/views/WeeklyNews/Listar.vue";
import FormWeeklyNews from "@/views/WeeklyNews/Form.vue";

import ListarListaDistribuicao from "@/views/ListaDistribuicao/Listar.vue";
import FormListaDistribuicao from "@/views/ListaDistribuicao/Form.vue";

import ListarPortos from "@/views/Portos/Listar.vue";
import FormPortos from "@/views/Portos/Form.vue";

import ListarGeneric from "@/views/Generic/Listar.vue";

import ListarCargasTipos from "@/views/CargasTipos/Listar.vue";
import FormCargasTipos from "@/views/CargasTipos/Form.vue";

import ListarLineups from "@/views/Lineups/Listar.vue";
import FormLineups from "@/views/Lineups/Form.vue";
import ImportarLineups from "@/views/Lineups/Importar.vue";

const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Email.vue");
Vue.use(Router);

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";
import admin from "@/middleware/admin";

const router = new Router({
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "dashboard",
      component: DashboardLayout,

      children: [
        {
          path: "/dashboard",
          name: "dashboard",
            redirect: "lineups/listar",
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Dashboard.vue"),
          meta: { middleware: auth },
        },
        {
          path: "/icons",
          name: "icons",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Icons.vue"),
        },
        {
          path: "/perfil",
          name: "Perfil",
          component: UserProfile,
        },
        {
          path: "/maps",
          name: "maps",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Maps.vue"),
        },
        {
          path: "/tables",
          name: "tables",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Tables.vue"),
        },
      ],
    },
    {
      path: "/",
      redirect: "login",
      component: AuthLayout,
      children: [
        {
          path: "/login",
          name: "login",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Login.vue"),
          meta: { middleware: guest },
        },
        {
          path: "/register",
          name: "register",
          component: () =>
            import(/* webpackChunkName: "demo" */ "./views/Register.vue"),
          meta: { middleware: guest },
        },
        {
          path: "/password/reset",
          name: "PasswordReset",
          component: PasswordReset,
          meta: { middleware: guest },
        },
        {
          path: "/password/email",
          name: "PasswordEmail",
          component: PasswordEmail,
          meta: { middleware: guest },
        },
      ],
    },
    {
      path: "/examples",
      component: DashboardLayout,
      name: "Examples",
      children: [
        {
          path: "user-profile",
          name: "User Profile",
          components: { default: UserProfile },
          meta: { middleware: auth },
        },
        {
          path: "user-management/list-users",
          name: "List Users",
          components: { default: ListUserPage },
          meta: { middleware: auth },
        },
      ],
    },
    {
      path: "/cargas_tipos",
      component: DashboardLayout,
      name: "Tipos de cargas",
      children: [
        {
          path: "adicionar",
          name: "Adicionar carga",
          components: { default: FormCargasTipos },
          meta: { middleware: admin },
        },
        {
          path: "editar/:id",
          name: "Editar carga",
          components: { default: FormCargasTipos },
          meta: { middleware: admin },
        },
        {
          path: "listar",
          name: "Listar tipos de carga",
          components: { default: ListarCargasTipos },
          meta: { middleware: admin },
        },
      ],
    },

    {
      path: "/lineups",
      component: DashboardLayout,
      name: "Lineups",
      children: [
        {
          path: "adicionar",
          name: "Adicionar lineup",
          components: { default: FormLineups },
          meta: { middleware: auth },
        },
         {
          path: "importar",
          name: "Importar lineups",
          components: { default: ImportarLineups},
          meta: { middleware: auth }
        },
        {
          path: "editar/:id",
          name: "Editar lineup",
          components: { default: FormLineups },
          meta: { middleware: auth },
        },
        {
          path: "listar",
          name: "Listar lineups",
          components: { default: ListarLineups },
          meta: { middleware: auth },
        },
      ],
    },
    {
      path: "/portos",
      component: DashboardLayout,
      name: "Portos",
      children: [
        {
          path: "adicionar",
          name: "Adicionar porto",
          components: { default: FormPortos },
          meta: { middleware: admin },
        },
        {
          path: "editar/:id",
          name: "Editar porto",
          components: { default: FormPortos },
          meta: { middleware: admin },
        },
        {
          path: "listar",
          name: "Listar portos",
          components: { default: ListarPortos },
          meta: { middleware: admin },
        },
      ],
    },
    {
      path: "/usuarios",
      component: DashboardLayout,
      name: "Usuários",
      children: [
        {
          path: "adicionar",
          name: "Adicionar usuário",
          components: { default: FormUsuarios },
          meta: { middleware: admin },
        },
        {
          path: "editar/:id",
          name: "Editar usuário",
          components: { default: FormUsuarios },
          meta: { middleware: admin },
        },
        {
          path: "listar",
          name: "Listar usuários",
          components: { default: ListarUsuarios },
          meta: { middleware: admin },
        },
        {
          path: "atividades",
          name: "Atividades usuários",
          components: { default: AtividadesUsuarios },
          meta: { middleware: admin },
        },
        {
          path: "atividades/:id",
          name: "Mostrar atividades usuário",
          components: { default: MostrarAtividadeUsuario },
          meta: { middleware: admin },
        },
      ],
    },
    {
      path: "/contatos",
      component: DashboardLayout,
      name: "Contatos",
      children: [
        {
          path: "adicionar",
          name: "Adicionar contato",
          components: { default: FormContatos },
          meta: { middleware: admin },
        },
           {
          path: "importar",
          name: "Importar contatos",
          components: { default: ImportarContatos},
          meta: { middleware: auth }
        },
        {
          path: "editar/:id",
          name: "Editar contato",
          components: { default: FormContatos },
          meta: { middleware: admin },
        },
        {
          path: "listar",
          name: "Listar contato",
          components: { default: ListarContatos },
          meta: { middleware: admin },
        },
      ],
    },
       {
      path: "/campos",
      component: DashboardLayout,
      name: "Campos da lineup",
      children: [
        {
          path: "navios",
          name: "Navios",
          components: { default: ListarGeneric},
          meta: { middleware: admin },
           force: true
        },
        {
          path: "agencias",
          name: "Agências",
          components: { default: ListarGeneric},
          meta: { middleware: admin },
        },
          {
          path: "afretadores",
          name: "Afretadores",
          components: { default: ListarGeneric},
          meta: { middleware: admin },
        },
        {
          path: "trabalhadores",
          name: "Trabalhadores",
          components: { default: ListarGeneric},
          meta: { middleware: admin },
        },
        {
          path: "lineup_porto",
          name: "Porto Lineup",
          components: { default: ListarGeneric},
          meta: { middleware: admin },
        },
      
      ],
    },
    {
      path: "/weekly_news",
      component: DashboardLayout,
      name: "Weekly News",
      children: [
        {
          path: "adicionar",
          name: "Adicionar weekly news",
          components: { default: FormWeeklyNews },
          meta: { middleware: admin },
        },
        {
          path: "editar/:id",
          name: "Editar weekly news",
          components: { default: FormWeeklyNews },
          meta: { middleware: admin },
        },
        {
          path: "listar",
          name: "Listar weekly news",
          components: { default: ListarWeeklyNews },

          meta: { middleware: admin }
        }
        ]
      },
        {
        path: "/lista_distribuicao",
        component: DashboardLayout,
        name: "Lista de distribuição",
        children: [
        {
          path: "adicionar",
          name: "Adicionar lista de distribuição",
          components: { default: FormListaDistribuicao },
          meta: { middleware: admin }
        },
        {
          path: "editar/:id",
          name: "Editar lista de distribuição",
          components: { default: FormListaDistribuicao},
          meta: { middleware: admin }
        },
        {
          path: "listar",
          name: "Listar",
          components: { default: ListarListaDistribuicao },
          meta: { middleware: admin }
        }
        ]
      },
      ]
    });


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = { from, next, to, router };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
