<template>
  <div>
    <div class="card mt-2">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12" v-if="allowDelete">
            <button
              class="btn base-button btn-danger float-right"
              @click="$emit('delete')"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
          <div class="col-lg-3">
            <base-select
              alternative=""
              label="Cargo type"
              :disabled="!cargasTipos.length"
              :errors="errors[errorSufix + 'carga_tipo_id']"
              placeholder="select the cargo type"
              :options="cargasTipos"
              @change="setCargaTipo(true)"
              input-classes="form-control-alternative"
              v-model="model.carga_tipo_id"
            />
          </div>
          <div class="col-lg-3">
            <multiple-select
              alternative=""
              label="Cargo"
              :disabled="!cargas.length"
              :errors="errors[errorSufix + 'carga_id']"
              addonLeftIcon=" fa fa-plus"
              placeholder="select the cargo"
              :options="cargas"
              @input="setCarga()"
              @btn="openModalCreate('carga')"
              :edit="true"
              @edit="openModalCreate('carga', null, null, model.carga_id)"
              input-classes="form-control"
              v-model="model.carga_id"
            />
          </div>
          <div class="col-lg-2">
            <div class="form-group has-label">
              <label class="form-control-label"> QTY </label>

              <money
                class="form-control form-control-alternative"
                v-model="model.qty"
                v-bind="qtd"
              ></money>
              <div
                class="text-danger invalid-feedback"
                style="display: block"
                v-for="erro in errors[errorSufix + 'qty']"
                v-if="errors[errorSufix + 'qty']"
              >
                {{ erro }}
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <base-select
              alternative=""
              label="Unity"
              :errors="errors.qty_unidade"
              :options="Service.unidades()"
              input-classes="form-control-alternative"
              v-model="model.qty_unidade"
              @change="setUnidade()"
            />
          </div>
          <div class="col-lg-2">
            <multiple-select
              alternative=""
              label="Ops"
              :errors="errors[errorSufix + 'ops_id']"
              placeholder="select the ops"
              :options="ops"
              optionsValue="name"
              required
              v-model="model.ops_id"
            />
          </div>

          <div class="col-lg-3" v-if="model.ops_id == 3 || model.ops_id == 1">
            <multiple-select
              alternative=""
              label="Last port"
              :errors="errors[errorSufix + 'import_porto_id']"
              placeholder="select the last port"
              :options="lineupPortos"
              optionsValue="port_name"
              addonLeftIcon=" fa fa-plus"
              required
              @btn="openModalCreate('lineup_porto', 'import_porto_id')"
              input-classes="form-control"
              v-model="model.import_porto_id"
              :edit="true"
              @input="setImport()"
              @edit="
                openModalCreate(
                  'lineup_porto',
                  'import_porto_id',
                  null,
                  model.import_porto_id
                )
              "
            />
          </div>

          <div class="col-lg-3" v-if="model.ops_id == 2 || model.ops_id == 1">
            <multiple-select
              alternative=""
              label="Next port"
              :errors="errors[errorSufix + 'export_porto_id']"
              placeholder="select the next port"
              :options="lineupPortos"
              optionsValue="port_name"
              addonLeftIcon=" fa fa-plus"
              @input="setExport()"
              @btn="openModalCreate('lineup_porto', 'export_porto_id')"
              :edit="true"
              @edit="
                openModalCreate(
                  'lineup_porto',
                  'export_porto_id',
                  null,
                  model.export_porto_id
                )
              "
              required
              input-classes="form-control"
              v-model="model.export_porto_id"
            />
          </div>
        </div>
        <div>
          <lineup_trabalhadores
            :model="model"
            :type="getType()"
            :trabalhadores="trabalhadores"
            :errors="errors"
            :errorSufix="errorSufix"
            :is-modal="isModal"
          />
        </div>
      </div>
    </div>
    <modal :show="modal.create.show" @close="handleCloseModal">
      <template v-slot:header> Add new </template>
      <template>
        <generic-form
          v-if="modal.create.show"
          :service="modal.create.service"
          :type="modal.create.type"
          :id="modal.create.id"
          :params="modal.create.params"
          @success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>
<script>
import Service from "@/services/Lineups";
import LineupTrabalhadores from "./Trabalhadores.vue";
import LineupsPortosService from "@/services/LineupsPortos";
import CargasService from "@/services/Cargas";
import NaviosService from "@/services/Navios";
import GenericForm from "./../../Generic/Form.vue";
import { Money } from "v-money";
export default {
  name: "lineups_cargas",
  components: {
    Money,
    lineup_trabalhadores: LineupTrabalhadores,
    "generic-form": GenericForm,
  },

  props: {
    isModal: {
      default: 0,
    },
    model: {
      // default: ()=>{},
    },
    cargasTipos: {
      default: () => [],
    },
    trabalhadores: {
      default: () => [],
    },
    lineupPortos: {
      default: () => [],
    },
    ops: {
      default: () => [],
    },
    errorSufix: {
      default: "",
    },
    errors: {
      default: () => [],
    },
    allowDelete: {
      default: false,
    },
  },
  data() {
    return {
      qtd: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: " (t)",
        precision: 2,
        masked: false,
      },

      cargas: [],
      loading: false,
      Service,
      modal: {
        create: {
          show: false,
          service: {},
          type: null,
          params: {},
          model: null,
          index: null,
        },
      },
    };
  },
  created() {
    this.setCargaTipo(false);
  },
  computed: {},
  watch: {
    cargasTipos: {
      deep: true,
      handler() {
        // console.log('watch',this.cargasTipos);
        this.setCargaTipo(false);
      },
    },
    errors: {
      deep: true,
      handler() {
        // console.log('errors',this.errors[this.errorSufix+'qty'],this.errorSufix+'qty');
      },
    },
    "model.carga_tipo": {
      deep: true,
      handler() {
        // console.log('watch',this.cargasTipos);
        this.setCargaTipo(false);
      },
    },
  },
  methods: {
    getType() {
      if (this.model.ops_id == 1) {
        if (this.model.import_porto_id) return 1;
        if (this.model.export_porto_id) return 2;
      }
      if (this.model.ops_id == 2) {
        return 2;
      }
      if (this.model.ops_id == 3) {
        return 1;
      }
      return null;
    },

    setCargaTipo(clean) {
      let tipo = this.cargasTipos.find(
        (term) => term.id == this.model.carga_tipo_id
      );

      if (tipo) {
        // this.model.carga_tipo = tipo;
        this.cargas = [...tipo.cargas];
      } else {
        // this.model.carga_tipo = {};
        // this.cargas = [];
        // this.model.carga_id = null;
      }
      if (clean) {
        this.model.carga_id = null;
      }
    },

    setCarga() {
      let carga = this.cargas.find((term) => term.id == this.model.carga_id);

      // console.log(carga);
      if (carga) {
        this.model.carga = carga;
      } else {
        this.model.carga = {};
      }
    },
    addTrabalhador(type) {
      if (type == 1) {
        //recebedor
        return this.model.recebedores.push({
          qty: 0,
          trabalhador_id: null,
          type: type,
        });
      }

      return this.model.embarcadores.push({
        qty: 0,
        trabalhador_id: null,
        type: type,
      });
    },
    openModalCreate(type, model, index, id) {
      this.modal.create.id = id;
      switch (type) {
        case "agencia":
          this.modal.create.service = AgenciasService;
          this.modal.create.params = {};
          break;

        case "navio":
          this.modal.create.service = NaviosService;
          this.modal.create.params = {};
          break;

        case "trabalhador":
          this.modal.create.service = TrabalhadoresService;
          this.modal.create.params = {};
          this.modal.create.model = model;
          this.modal.create.index = index;
          break;

        case "afretador":
          this.modal.create.service = AfretadoresService;
          this.modal.create.params = {};

          break;
        case "lineup_porto":
          this.modal.create.service = LineupsPortosService;
          this.modal.create.params = {};
          this.modal.create.model = model;

          break;

        case "carga":
          if (!this.model.carga_tipo_id) {
            this.$notify({
              type: "danger",
              message: "Please select the cargo type",
              horizontalAlign: "center",
            });
            return false;
          }
          this.modal.create.service = CargasService;
          this.modal.create.params = { tipo_id: this.model.carga_tipo_id };
          break;
      }

      this.modal.create.type = type;
      this.modal.create.show = true;
    },
    handleModalForm(model) {
      let arrayOfFields = null;
      switch (this.modal.create.type) {
        case "trabalhador":
          this.model[this.modal.create.model][this.modal.create.index][
            "trabalhador_id"
          ] = model.id;
          arrayOfFields = "trabalhadores";
          // this.trabalhadores.push(model);
          break;
        case "lineup_porto":
          this.model[this.modal.create.model] = model.id;
          // this.lineupPortos.push(model);
          arrayOfFields = "lineupPortos";
          break;
        case "afretador":
          this.model.afretador_id = model.id;
          // this.afretadores.push(model);
          arrayOfFields = "afretadores";
          break;
        case "carga":
          this.model.carga_id = model.id;
          arrayOfFields = "cargas";
          // this.cargas.push(model);
          break;
      }

      if (this.modal.create.id) {
        let field = this[arrayOfFields].find((field) => field.id == model.id);
        this.$helper.set(field, model, Object.keys(field));
      } else {
        this[arrayOfFields].unshift(model);
      }

      this.modal.create.type = null;
      this.modal.create.show = false;
      if (!this.isModal)
        document.querySelector("body").classList.remove("modal-open");
      // this.$forceUpdate();
    },
    handleCloseModal() {
      this.modal.create.show = false;
      if (!this.isModal)
        document.querySelector("body").classList.remove("modal-open");
    },
    setImport() {
      // this.model.ie_import = this.model.ie_import ? 0 : 1;
      this.model.export_porto_id = null;
      this.$forceUpdate();
    },
    setType(type) {
      this.$helper.set(
        this.model,
        { ...this.modelDefault, is_observacao: type },
        Object.keys(this.model)
      );
    },
    resetModel() {
      this.$helper.set(this.model, this.modelDefault, Object.keys(this.model));
    },
    setUnidade() {
      this.qtd.suffix = " (" + this.model.qty_unidade + ")";
    },
    setExport() {
      this.model.import_porto_id = null;
      this.$forceUpdate();
    },
  },
};
</script>
<style>
button.input-padding {
  padding: 0.625rem 0.75rem;
}
.transparent {
  color: transparent;
}
</style>
