import axios from "axios";
import { helper } from "../helper.js";
export default class Abstract {    

	constructor(url) {
		this.url = url;
	}
	get(params){      
		if(!params){
			params = {};
		}
		return axios.get(this.url,{params: {...params}});
	}

	find(id,params){      
		if(!params){
			params = {};
		}
		return axios.get(this.url+ '/' + id,{params: {...params}});
	}

	update(id,params){             
		return axios.post(this.url+ '/' + id, params);
	}

	create(params){             
		return axios.post(this.url,params);
	}

	delete(id){             
		return axios.post(this.url +'/'+ id,{'_method':'delete'});
	}

	submitOld(model,formData){    		
		if(model.id){
			let params = formData ? helper.getFormDataold({...model,'_method': 'put'}) : {...model,'_method': 'put'};
			return this.update(model.id,params);
		}

		let params = formData ? helper.getFormDataold(model) : model;
		return this.create(params);
	}

	submit(model){    		
		if(model.id){
			let params = helper.getFormDataTrue({...model,'_method': 'put'});
			return this.update(model.id,params);
		}

		let params = helper.getFormDataTrue(model);
		return this.create(params);
	}

	addImagem(model){    				
		let params =  helper.getFormData({...model}) ;
		return axios.post(this.url+ '/imagem', params);
	}
	
	addArquivo(model){    				
		let params =  helper.getFormData({...model}) ;
		return axios.post(this.url+ '/arquivos', params);
	}


	deleteImagem(imagem){             
		return axios.post(this.url+ '/imagem/'+ imagem.id,{'_method':'delete'});
	}



}