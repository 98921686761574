<template>
  <div>
    <base-header class="pb-6 pb-6 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Weekly news</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information of weekly news
                </h6>

                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      required
                      label="Subject"
                      placeholder="subject"
                      input-classes="form-control-alternative"
                      :errors="errors.assunto"
                      v-model="model.assunto"
                    />
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        Send date<span>*</span></label
                      >
                      <datetime
                        value-zone="America/Sao_Paulo"
                        zone="America/Sao_Paulo"
                        type="datetime"
                        input-class="form-control form-control-alternative"
                        v-model="model.data_envio"
                      ></datetime>
                      <div
                        class="text-danger invalid-feedback"
                        style="display: block"
                        v-for="erro in errors['data_envio']"
                        v-if="errors['data_envio']"
                      >
                        {{ erro }}
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group has-label">
                      <label class="form-control-label">
                        Email body<span>*</span></label
                      >
                      <ckeditor
                        :editor="editor"
                        v-model="model.body"
                        :config="editorConfig"
                      ></ckeditor>
                      <div
                        class="text-danger invalid-feedback"
                        style="display: block"
                        v-for="erro in errors['body']"
                        v-if="errors['body']"
                      >
                        {{ erro }}
                      </div>
                    </div>
                  </div>
                </div>
                <h6 class="heading-small text-muted mb-1">
                  Select the group that will receive this email
                </h6>
                <div
                  class="text-danger invalid-feedback"
                  style="display: block"
                  v-for="erro in errors['cargas']"
                  v-if="errors['cargas']"
                >
                  {{ erro }}
                </div>
                <button
                  class="btn btn-sm btn-primary mb-2"
                  @click="setCargas(true)"
                >
                  Add all
                </button>
                <button
                  class="btn btn-sm btn-danger mb-2"
                  @click="setCargas(false)"
                >
                  Remove all
                </button>
                <div class="row mb-3">
                  <div
                    class="col-lg-2 col-md-3"
                    v-for="(carga, index) in cargas"
                  >
                    <div class="card">
                      <div class="card-body">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            :id="index"
                            :value="carga.id"
                            v-model="model.cargas"
                          />
                          <label class="form-check-label" :for="index">{{
                            carga.name
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>

            <button
              type="submit"
              class="btn base-button btn-dark float-left"
              @click="$router.push('/weekly_news/listar')"
            >
              See listing
            </button>
            <button
              type="submit"
              class="btn base-button btn-primary float-right"
              :disabled="loading"
              @click="submit()"
            >
              Save
            </button>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue-datetime/dist/vue-datetime.css";
import Service from "@/services/WeeklyNews";
import { Datetime } from "vue-datetime";
import AgenciasService from "@/services/Agencias";
import CargasTiposService from "@/services/CargasTipos";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import "@ckeditor/ckeditor5-build-classic/build/translations/pt";
export default {
  name: "usuario_form",
  components: {
    datetime: Datetime,
  },
  created() {
    AgenciasService.get({}).then((response) => {
      this.agencias = response.data;
    });
    CargasTiposService.get({}).then((response) => {
      this.cargas = response.data;
    });
    if (this.$route.params.id) {
      this.model.id = this.$route.params.id;
      this.find();
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // language: "pt",
        heigth: 600,
      },
      errors: {},
      agencias: [],
      cargas: [],
      loading: false,
      portos: [],
      Service,

      model: {
        nome: "",
        email: "",
        data_envio: null,
        cargas: [],
        id: null,
      },
    };
  },
  methods: {
    find() {
      this.loading = true;
      this.errors = {};
      Service.find(this.model.id, { with: "cargas_tipos" })
        .then((response) => {
          this.model = response.data;
          this.model.cargas = [];
          this.fixCargas();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    fixCargas() {
      let cargas = this.model.cargas_tipos.map((carga) => carga.id);
      this.$set(this.model, "cargas", cargas);
    },
    setCargas(adicionar) {
      if (adicionar) {
        this.model.cargas = this.cargas.map((carga) => carga.id);
      } else {
        this.model.cargas = [];
      }
      this.$forceUpdate();
    },

    submit() {
      this.loading = true;
      this.errors = {};
      Service.submit(this.model)
        .then((response) => {
          this.model = response.data;
          this.fixCargas();
          this.loading = false;
          this.$notify({
            type: "success",
            message: "Weekly News saved successfully!",
            horizontalAlign: "center",
          });
        })
        .catch((err) => {
          this.loading = false;
          if (err.response && err.response.data && err.response.data.errors) {
            let errors = err.response.data.errors;
            this.errors = errors;
            this.$notify({
              type: "danger",
              message: "Please check the form",
              horizontalAlign: "center",
            });
          }
        });
    },
  },
};
</script>
<style>
.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
