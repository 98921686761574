import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = '/lineups';
export const url = process.env.VUE_APP_API_BASE_URL+ base_url;
export const abstract = new Abstract(url);
export default class Lineups {    

	
	static  get(params){      		
		return abstract.get({...params});
	}

	static  find(id,params){      
		return abstract.find(id,params); 
	}

	static update(model){             
		return axios.post(url + '/' + model.id,helper.getFormData({...model,'_method': 'put'}));
	}

	static atualizar(model,atualizar){             
		return axios.post(url + '/' + model.id + '/atualizar' ,{atualizar:atualizar});
	}

	static alteraData(model,campo){             
		return axios.post(url + '/' + model.id + '/data' ,{campo:campo,valor:model[campo]});
	}
	static create(model){             
		return axios.post(url,helper.getFormData(model));
	}
	
	static submit(model){    				
		return abstract.submit(model,false); // qnd true é passado é para ser convertido em formData
	}

	static delete(id){
		return abstract.delete(id);
	};

	static  parametros(params){      		
		return axios.get(url + '/parametros' ,{params: {...params}});
	}

	static  download(params){      		
		return axios.post(url + '/download' , {...params}
			, {
				// headers: {
				// 	'Content-Type': 'application/json',
				// 	'Accept': '*'
				// },
				responseType: "blob"
			});
	}

	static  importar(params){      		
		return axios.post(url + '/importar' , helper.getFormDataold({...params})
			);
	}

	static unidades(){
		return [
		{id:'t',name:'Tonelada'},
		{id:'m³',name:'Metro cúbico'},		
		];
	};
	
	static opcaoTipo(){
		return [
		{id:0,name:'Lineup'},
		{id:1,name:'Observação'},		
		];
	};

	static addCarga(model,premodel){
		let carga = { 
			carga_tipo_id: null,
			carga: {},
			carga_id: null,
			export_porto_id: null,
			import_porto_id: null,
			qty: 0,
			qty_unidade: "t",
			ops:null,
			embarcadores: [],
			recebedores: [],
		}

		if(premodel){
			helper.set(carga,premodel,Object.keys(premodel));
		}

		return model.lineups_cargas.push(carga);
	}

	static addLineup(list,premodel){

		let model = {
			is_observacao: 0,
			porto: {},
			terminal: {},
			embarcadores: [],
			recebedores: [],
			afretador_id: null,
			agencia_id: null,
			navio_id: null,
			porto_id: null,
			terminal_id: null,
			berco_id: null,
			carga_tipo_id: null,
			carga: {},
			carga_id: null,
			export_porto: null,
			import_porto: null,
			qty: 0,
			qty_unidade: "t",
			qty_import: 0,
			qty_export: 0,
			id: null,
			lineups_cargas:[]
		}

		if(premodel){
			helper.set(model,premodel,Object.keys(premodel));
		}

		return list.push(model);

	}
	
	static saveList(list, terminal_id,deleted_lines) {
		return axios.post(url + "/list", { list, terminal_id,deleted_lines });
	}

}