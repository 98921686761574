<template>
  <div class="table-responsive" style="overflow-x: scroll; overflow: visible">
    <table
    class="table align-items-center table-flush tablesorter"
    tbody-classes="list"
    :data="list"
    >
    <thead class="thead-light">
      <tr v-if="list.length">
        <th>Vessel</th>
        <th v-if="carga.name == 'TANKERS'">Berth</th>
        <th>ETA</th>
        <th>ETB</th>
        <th>ETS</th>
        <th>Wait</th>
        <th>Last port</th>
        <th>Next port</th>
        <th>Ops</th>
        <th>Cargo Type</th>
        <th>Cargo</th>
        <th v-if="carga.name != 'TANKERS'">QTY</th>
        <th v-if="carga.name == 'TANKERS'">QTY DISCH</th>
        <th v-if="carga.name == 'TANKERS'">QTY LOADED</th>
        <th>Charterer</th>

        <th>Updated on</th>
        <th></th>
      </tr>
    </thead>

    <tbody class="list">
      <template v-for="(row, index) in list" >
        <tr >
          <td>
            <span v-if="!row.is_observacao">
              {{ row.navio.name }}
              <b v-if="row.call_number">
                *{{ row.call_number + (row.call_number > 1 ? "ND" : "ST") }}
                CALL
              </b>
            </span>
            <span v-else>
              {{ row.observacao }}
            </span>
          </td>
          <td v-if="carga.name == 'TANKERS'">
            {{ row.berco ? row.berco.name : "" }}
          </td>
          <td>
            <template v-if="!row.is_observacao">
              <span v-if="carga.name == 'TANKERS'">
                {{ row.eta | dMhM }}
              </span>
              <span v-else>
                {{ row.eta | dateHour }}
              </span>
            </template>
          </td>
          <td>
            <span v-if="carga.name == 'TANKERS'">
              {{ row.etb | dMhM }}
            </span>
            <span v-else>
              {{ row.etb | dateHour }}
            </span>
          </td>
          <td>
            <span v-if="carga.name == 'TANKERS'">
              {{ row.ets | dMhM }}
            </span>
            <span v-else>
              {{ row.ets | dateHour }}
            </span>
          </td>
          <td style="text-align: center">{{ row.espera || "-" }}</td>
          <td>
            <span v-if="!row.is_observacao">
              {{ row.import_porto.port_name }}</span
              >
            </td>
            <td>
              <span v-if="!row.is_observacao">
                {{ row.export_porto.port_name }}</span
                >
              </td>
              <td>
                <span v-if="!row.is_observacao"> {{ row.ops.name }}</span>
              </td>
              <td>
                <span v-if="!row.is_observacao"> {{ row.carga_tipo.name }}</span>
              </td>
              <td>
                <span v-if="!row.is_observacao"> {{ row.carga.name }}</span>
              </td>
              <td v-if="carga.name != 'TANKERS'">
                <span v-if="row.qty > 0 ">{{ row.qty | qtd }} {{ row.qty_unidade }}</span>
              </td>
              <td v-if="carga.name == 'TANKERS'">                    
                <span v-if="(row.ops_id == 3 || (row.ops_id == 1 && row.import_porto_id  )) && row.qty > 0">
                  {{ row.qty | qtd }} {{ row.qty_unidade }}
                </span>
              </td>
              <td v-if="carga.name == 'TANKERS'">
                <span v-if="(row.ops_id == 2 || (row.ops_id == 1 && row.export_porto_id  )) && row.qty > 0">
                  {{ row.qty | qtd }} {{ row.qty_unidade }}
                </span>
              </td>

              <td>{{ row.afretador.name }}</td>

              <td>{{ row.atualizado_data | date }}</td>
              <td class="text-right">
                <base-dropdown class="dropdown" position="right">
                <a
                slot="title"
                class="btn btn-sm btn-icon-only text-light"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-auto-close="inside"
                >
                <i class="fas fa-ellipsis-v"></i>
              </a>

              <template>
                <a class="dropdown-item" @click="marcarAtualizado(row, 1)"
                ><i class="fas fa-check" style="min-width: 20px"></i> Mark as
                updated</a
                >
                <a
                class="dropdown-item"
                @click="
                $emit('openModal', {
                  ...row,
                  call_in_id: undefined,
                })
                "
                ><i class="fas fa-edit"></i> edit</a
                >
                <a class="dropdown-item p-0" @click.stop>
                  <multiple-select
                  :options="terminais"
                  v-model="row.terminal_id"
                  placeholder="Select terminal"
                  class="m-0 rounded-0"
                  @input="alteraData(row, 'terminal_id')"
                  />
                </a>
                <a
                class="dropdown-item"
                @click="
                $emit('openModal', {
                  ...row,
                  call_in_id: row.id,
                })
                "
                ><i class="fas fa-plus"></i> add call</a
                >
                <a class="dropdown-item" @click="marcarAtualizado(row, 0)"
                ><i class="fas fa-ban" style="min-width: 20px"></i> Remove
                updated</a
                >
                <a
                class="dropdown-item"
                @click="
                $root.openExcluirModal(
                  row,
                  'Would you like to delete the lineup?',
                  Service
                  )
                  "
                  ><i class="fas fa-trash" style="min-width: 20px"></i>
                  remove</a
                  >
                </template>
              </base-dropdown>
            </td>
          </tr>


          <!-- cargas adicionais -->
          <tr v-for="(cargaRow,indexRow) in row.lineups_cargas">
            <td></td>
            <td v-if="carga.name == 'TANKERS'"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>      
              <span v-if="(indexRow == 0 && row.import_porto_id != cargaRow.import_porto_id) || (indexRow >=1 && row.lineups_cargas[indexRow-1].import_porto_id != cargaRow.import_porto_id)">{{cargaRow.last_port.name}}</span>
            </td>
            <td>
              <span v-if="(indexRow == 0 && row.export_porto_id != cargaRow.export_porto_id) || (indexRow >=1 && row.lineups_cargas[indexRow-1].export_porto_id != cargaRow.export_porto_id)">{{cargaRow.next_port.name}}</span>
           </td>
           <td>
            <span v-if="(indexRow == 0 && row.ops_id != cargaRow.ops_id) || (indexRow >=1 && row.lineups_cargas[indexRow-1].ops_id != cargaRow.ops_id)">{{cargaRow.ops.name}}</span>
          </td>
          <td>
            <span v-if="(indexRow == 0 && row.carga_tipo_id != cargaRow.carga_tipo_id) || (indexRow >=1 && row.lineups_cargas[indexRow-1].carga_tipo_id != cargaRow.carga_tipo_id)">{{cargaRow.carga_tipo.name}}</span>
          </td>
          <td>
            <span v-if="(indexRow == 0 && row.carga_id != cargaRow.carga_id) || (indexRow >=1 && row.lineups_cargas[indexRow-1].carga_id != cargaRow.carga_id)">{{cargaRow.carga.name}}</span>
          </td>
          <td v-if="carga.name != 'TANKERS'">
            <span v-if="cargaRow.qty > 0 ">{{ cargaRow.qty | qtd }} {{ cargaRow.qty_unidade }}</span>
          </td>
          <td v-if="carga.name == 'TANKERS'">                    
            <span v-if="(cargaRow.ops_id == 3 || (cargaRow.ops_id == 1 && cargaRow.import_porto_id  )) && cargaRow.qty > 0">
              {{ cargaRow.qty | qtd }} {{ cargaRow.qty_unidade }}
            </span>
          </td>
          <td v-if="carga.name == 'TANKERS'">
            <span v-if="(cargaRow.ops_id == 2 || (cargaRow.ops_id == 1 && cargaRow.export_porto_id  )) && cargaRow.qty > 0">
              {{ cargaRow.qty | qtd }} {{ cargaRow.qty_unidade }}
            </span>
          </td>
          <td></td>
          <td></td>
          <td></td>

        </tr>
      </template>
      <!-- /cargas adicionais -->

      <tr v-if="!list.length && !loading">
        <td colspan="100" style="text-align: center">Nenhuma lineup</td>
      </tr>
      <tr v-if="loading">
        <td colspan="100" style="text-align: center">
          <i
          class="fa fa-spinner fa-spin"
          v-if="loading"
          style="font-size: 20px"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</template>
<script>
  import Service from "@/services/Lineups";
  export default {
    props: {
      lineups: {
        default: () => [],
      },
      carga_tipo_id: {
        default: 0,
      },
      carga: {
        default: () => {},
      },
      terminais: {
        default: () => [],
      },
    },
    data() {
      return {
        Service,
        loading: false,

        search: {
          terminal_id: null,
          carga_tipo_id: null,
        },
        orderBy: {
          campo: "etb",
          ordem: "asc",
        },
        list: [
        // {
        //   name: "Admin",
        //   email: "admin@jsonapi.com",
        //   created_at: "2020-01-01",
        // },
        ],
      };
    },
    created() {
      if (this.lineups) {
        this.list = this.lineups;
      } else {
        this.listar();
      }
    },
    watch: {
      lineups: {
        deep: true,
        handler() {
          this.list = this.lineups;
        },
      },
      search: {
        deep: true,
        handler() {
          this.listar();
        },
      },
      orderBy: {
        deep: true,
        handler() {
          this.pagination.page = 1;
          this.listar();
        },
      },
    },
    methods: {
      handleExcluir(updated) {
        this.$root.modal.excluir.isOpen = false;
        if (updated) this.$parent.listar();
      },
      marcarAtualizado(lineup, atualizar) {
        this.loading = true;
        Service.atualizar(lineup, atualizar).then((response) => {
          lineup.atualizado_data = response.data.atualizado_data;
          this.$notify({
            type: "success",
            message: "Atualizado com sucesso!",
            horizontalAlign: "center",
          });
          this.loading = false;
        });
      },
      alteraData(lineup, campo) {
        Service.alteraData(lineup, campo).then((response) => {
          lineup[campo] = response.data[campo];
          lineup.espera = response.data.espera;
        });
      },
      listar() {
        this.loading = true;
        this.list = [];
        Service.get({
          ...this.search,
          with: [
          "carga",
          "cargaTipo",
          "terminal",
          "berco",
          "recebedores",
          "afretador",
          "embarcadores",
          "agencia",
          "navio",
          "importPorto",
          "exportPorto",
          "lineupsCargas"
          ],
        }).then((response) => {
          this.list = response.data;
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.card .table td,
.card .table th {
  padding-left: 0.1rem;
  padding-right: 0.1rem;
}

.table td,
.table th {
  font-size: 0.6125rem;
  white-space: normal;
  padding: 0.1rem 1rem;
}

.hover-style {
  padding: 0;
  margin: 0;
  height: auto;
  font-size: 0.8125rem;
  border: none;
  width: 5.7125rem;
}

/*.hover-style:focus,.hover-style:hover{
  border:1px solid #cad1d7;
  padding: 0.625rem 0.75rem;
  width: 6.6125rem;


  }*/
/*input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    }*/
    .dropdown-item {
      cursor: pointer;
    }
  </style>
