import Abstract from "./Abstract";
export const base_url = '/logs/users';
export const url = process.env.VUE_APP_API_BASE_URL+ base_url;
export const abstract = new Abstract(url);

export default class Usuarios {

	static  get(params){
		return abstract.get({...params});
	}

	static  find(id,params){
		return abstract.find(id,params);
	}
}