import axios from "axios";
import { helper } from "../helper.js";
import Abstract from "./Abstract";
export const base_url = '/contatos';
export const url = process.env.VUE_APP_API_BASE_URL+ base_url;
export const abstract = new Abstract(url);
export default class Contatos {    

	
	static  get(params){      		
		return abstract.get({...params});
	}

	static  find(id,params){      
		return abstract.find(id,params); 
	}

	static update(model){             
		return axios.post(url + '/' + model.id,helper.getFormData({...model,'_method': 'put'}));
	}

	static create(model){             
		return axios.post(url,helper.getFormData(model));
	}
	
	static submit(model){    				
		return abstract.submit(model,false); // qnd true é passado é para ser convertido em formData
	}

	static delete(id){
		return abstract.delete(id);
	};

		static  download(params){      		
		return axios.post(url + '/download' , {...params}
			, {
				// headers: {
				// 	'Content-Type': 'application/json',
				// 	'Accept': '*'
				// },
				responseType: "blob"
			});
	}

	static  importar(params){      		
		return axios.post(url + '/importar' , helper.getFormDataold({...params})
		);
	}



}