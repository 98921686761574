<template>
  <div class="main-content bg-default">
    <!-- Navbar -->
 
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-4">
          <div class="text-center" style="margin-bottom: 5px">
            <div v-if="isLogin">
              <h1 class="text-white">
               <img src="/img/logo-site.png">
              </h1>
             
            
            </div>
       
            <div v-if="isPassword">
              <div class="row justify-content-center">
                <div class="col-xl-5 col-lg-6 col-md-8 px-5">
                  <p class="pb-0"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <slide-y-up-transition mode="out-in" origin="center top">
        <router-view></router-view>
      </slide-y-up-transition>
    </div>
   
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  computed: {
    isLogin() {
      return this.$route.name === "login";
    },
    isRegister() {
      return this.$route.name === "register";
    },
    isPassword() {
      return this.$route.name === "PasswordReset";
    },
  },
};
</script>
<style></style>
