var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[(_vm.$root.showDesktopSidebar)?_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Williams","title":"Williams"}},[_c('template',{slot:"links"},[(_vm.$root.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Cargo',
          icon: 'fa fa-truck text-green',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/cargas_tipos/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/cargas_tipos/listar',
            icon: 'fa fa-list',
          }}})],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Lineups',
          icon: 'fa fa-book-open text-dark',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/lineups/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Import', path: '/lineups/importar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/lineups/listar',
            icon: 'fa fa-list',
          }}})],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Fields of lineup',
          icon: 'fa fa-book-open text-dark',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Charterers', path: '/campos/afretadores' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Agencies', path: '/campos/agencias' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Vessels', path: '/campos/navios' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Port Lineup', path: '/campos/lineup_porto' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Shippers / Receivers',
            path: '/campos/trabalhadores',
          }}})],1),(_vm.$root.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Ports',
          icon: 'fa fa-ship text-blue',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/portos/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/portos/listar',
            icon: 'fa fa-list',
          }}})],1):_vm._e(),(_vm.$root.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Users',
          icon: 'fa fa-users text-yellow',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/usuarios/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/usuarios/listar',
            icon: 'fa fa-list',
          }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Activities', path: '/usuarios/atividades' }}})],1):_vm._e(),(_vm.$root.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Contacts',
          icon: 'fa fa-address-book ',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/contatos/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/contatos/listar',
            icon: 'fa fa-list',
          }}})],1):_vm._e(),(_vm.$root.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'WeeklyNews',
          icon: 'fa fa-paper-plane ',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/weekly_news/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/weekly_news/listar',
            icon: 'fa fa-list',
          }}})],1):_vm._e(),(_vm.$root.isAdmin)?_c('sidebar-item',{attrs:{"link":{
          name: 'Distribution list',
          icon: 'fa fa-file-excel',
        }}},[_c('sidebar-item',{attrs:{"link":{ name: 'New', path: '/lista_distribuicao/adicionar' }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'List',
            path: '/lista_distribuicao/listar',
            icon: 'fa fa-list',
          }}})],1):_vm._e()],1)],2):_vm._e(),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }