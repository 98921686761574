<template>
  <div>
    <base-header
      type="gradient-success"
      class="pb-6 pb-6 pt-5 pt-md-5"
    ></base-header>
    <div class="container-fluid mt-5">
      <card body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0">
                List <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>
            <div class="col text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="$router.push('/usuarios/adicionar')"
              >
                <i class="fas fa-user-edit mr-2"></i>
                <span class="btn-inner--text">Adicionar Usuário</span>
              </base-button>
            </div>
          </div>
          <div class="row align-items-center mt-3">
            <div class="col-lg-1">
              <div class="form-group has-label">
                <label class="form-control-label" style="color: transparent">
                  search</label
                >
                <button
                  type="submit"
                  class="btn base-button btn-primary w-100"
                  :disabled="loading"
                  @click="listar()"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive" style="overflow: visible">
          <table
            class="table align-items-center table-flush tablesorter"
            tbody-classes="list"
            :data="list"
          >
            <thead class="thead-light">
              <tr>
                <th>Activities</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="list">
              <tr v-for="(row, index) in list" :key="list.id">
                <td>
                  {{ row.description }}
                </td>
                <td
                  v-if="row.action == 'editou'"
                  class="text-primary cursor-pointer"
                  @click="
                    $router.push({
                      name: 'Mostrar atividades usuário',
                      params: { id: row.id },
                    })
                  "
                >
                  See Updates
                </td>
                <td v-else></td>
              </tr>
              <tr v-if="!list.length && !loading">
                <td colspan="100" style="text-align: center">
                  No user activities found
                </td>
              </tr>
              <tr v-if="loading">
                <td colspan="100" style="text-align: center">
                  <i
                    class="fa fa-spinner fa-spin"
                    v-if="loading"
                    style="font-size: 20px"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <base-pagination
            :pagination="pagination"
            @change="listar()"
            align="center"
          ></base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import Service from "@/services/UserLogs";
export default {
  data() {
    return {
      Service,
      loading: false,
      pagination: {
        page: 1,
        last_page: 1,
        per_page: 20,
        total: 1,
      },
      search: {
        name: "",
        email: "",
        role_id: null,
      },
      orderBy: {
        campo: "FORNECEDOR",
        ordem: "asc",
      },
      list: [],
    };
  },
  created() {
    this.listar();
  },
  watch: {
    orderBy: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.listar();
      },
    },
  },
  methods: {
    listar() {
      this.loading = true;
      this.list = [];
      Service.get({
        ...this.pagination,
        ...this.search,
      }).then((response) => {
        this.list = response.data.data;
        this.pagination.last_page = response.data.last_page;
        this.pagination.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
