<template> 
	<div>
		<input type="file" v-bind="$attrs" ref="inputFile" @change="setFile">
		<div class="text-danger invalid-feedback" style="display: block;"  v-for="erro in errors" v-if="errors">
			{{ erro }}
		</div>
	</div>
	
</template>
<script>
	export default{
		props: {
			value:'',
			errors:{
				type:Object,
				default:()=>{}
			}
		},
		
		data() {
			return { 
				file: null
			}
		},
		methods:{
			setFile: function(){
				console.log(this.$refs.inputFile.files);
                // this.model.arquivo = this.$refs.inputFile.files;
                if(!this.$refs.inputFile.files.length){
                	return this.$emit('input', null);
                }
                // this.file = this.$refs.inputFile.files;
                return this.$emit('input', this.$refs.inputFile.files);
                
            },
        }
    }
</script>

<style scoped="">
</style>